/* eslint-disable max-len */
import { get } from 'ui/lodash';
const emptyShop = '/images/store/shop.svg';

export const getUserIcon = (user) => {

  const picture = get(user, 'picture', null);
  if (picture) {

    return picture;

  }
  const shop = get(user, 'icon', []);
  if (shop.length > 0) {

    return shop[0];

  }
  return '/images/man.svg';

};

export const getShopIcon = (src) => {

  if (src) {

    return src || emptyShop;

  }
  return emptyShop;

};

export const getTileIcon = (tile) => {

  const image = '/images/feed/single.svg';
  const multi = '/images/feed/multi.svg';
  const short = '/images/feed/short.svg';
  const long = '/images/feed/long.svg';
  const type = get(tile, 'postType', '');
  if (type === 'image') {

    return image;

  }
  if (type === 'image carousel') {

    return multi;

  }
  const duration = get(tile, 'duration', 0);
  if (duration > 120000) {

    return long;

  }
  return short;

};

export const discountCouponIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1276 8.90591L19.8282 7.60613C19.6357 7.41357 19.5394 7.12473 19.5394 6.88403V5.43983C19.5394 3.80306 18.1919 2.45514 16.5556 2.45514H15.1118C14.823 2.45514 14.5824 2.35886 14.3899 2.1663L13.0905 0.866521C11.9354 -0.28884 10.0585 -0.28884 8.90343 0.866521L7.60401 2.1663C7.41151 2.35886 7.12275 2.45514 6.88211 2.45514H5.43831C3.80201 2.45514 2.45446 3.80306 2.45446 5.43983V6.88403C2.45446 7.17287 2.35821 7.41357 2.1657 7.60613L0.86628 8.90591C-0.28876 10.0613 -0.28876 11.9387 0.86628 13.0941L2.1657 14.3939C2.35821 14.5864 2.45446 14.8753 2.45446 15.116V16.5602C2.45446 18.1969 3.80201 19.5449 5.43831 19.5449H6.88211C7.17087 19.5449 7.41151 19.6411 7.60401 19.8337L8.90343 21.1335C9.48095 21.7112 10.251 22 11.021 22C11.791 22 12.5611 21.7112 13.1386 21.1335L14.438 19.8337C14.6305 19.6411 14.9193 19.5449 15.1599 19.5449H16.6037C18.24 19.5449 19.5876 18.1969 19.5876 16.5602V15.116C19.5876 14.8271 19.6838 14.5864 19.8763 14.3939L21.1757 13.0941C22.2826 11.9869 22.2826 10.0613 21.1276 8.90591ZM19.7801 11.7462L18.4806 13.046C17.9031 13.6236 17.6144 14.3457 17.6144 15.1641V16.6083C17.6144 17.186 17.1331 17.6674 16.5556 17.6674H15.1118C14.2936 17.6674 13.5717 17.9562 12.9942 18.5339L11.6948 19.8337C11.2616 20.2188 10.636 20.2188 10.2029 19.8337L8.90343 18.5339C8.32591 17.9562 7.60401 17.6674 6.78586 17.6674H5.34206C4.76454 17.6674 4.28327 17.186 4.28327 16.6083V15.1641C4.28327 14.3457 3.99451 13.6236 3.41699 13.046L2.11757 11.7462C1.68443 11.3129 1.68443 10.6871 2.11757 10.2538L3.41699 8.95405C3.99451 8.37637 4.28327 7.65427 4.28327 6.83589V5.39168C4.28327 4.814 4.76454 4.3326 5.34206 4.3326H6.78586C7.60401 4.3326 8.32591 4.04376 8.90343 3.46608L10.2029 2.1663C10.636 1.78118 11.2616 1.78118 11.6948 2.1663L12.9942 3.46608C13.5717 4.04376 14.2936 4.3326 15.1118 4.3326H16.5556C17.1331 4.3326 17.6144 4.814 17.6144 5.39168V6.83589C17.6144 7.65427 17.9031 8.37637 18.4806 8.95405L19.7801 10.2538C20.1651 10.6871 20.1651 11.361 19.7801 11.7462Z" fill="var(--green)" />
    <path d="M13.1382 7.46169L7.36298 13.2385C6.97797 13.6236 6.97797 14.2013 7.36298 14.5864C7.55549 14.779 7.79612 14.8753 8.03675 14.8753C8.27739 14.8753 8.51802 14.779 8.71053 14.5864L14.4857 8.80961C14.8707 8.42449 14.8707 7.84681 14.4857 7.46169C14.1007 7.07657 13.5232 7.07657 13.1382 7.46169Z" fill="var(--green)" />
    <path d="M8.03675 9.09845C8.56834 9.09845 8.99929 8.66739 8.99929 8.13565C8.99929 7.60391 8.56834 7.17285 8.03675 7.17285C7.50516 7.17285 7.07422 7.60391 7.07422 8.13565C7.07422 8.66739 7.50516 9.09845 8.03675 9.09845Z" fill="var(--green)" />
    <path d="M13.8121 14.8753C14.3437 14.8753 14.7747 14.4442 14.7747 13.9125C14.7747 13.3808 14.3437 12.9497 13.8121 12.9497C13.2806 12.9497 12.8496 13.3808 12.8496 13.9125C12.8496 14.4442 13.2806 14.8753 13.8121 14.8753Z" fill="var(--green)" />
  </svg>
);

export const chevronDown = () => (
  <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.00427 4.88995L10.5419 0.256981C10.6212 0.175538 10.7156 0.110908 10.8196 0.0668061C10.9235 0.0227039 11.035 -4.23104e-08 11.1476 -3.73895e-08C11.2601 -3.24686e-08 11.3716 0.022704 11.4755 0.0668061C11.5795 0.110908 11.6739 0.175538 11.7532 0.256981C11.9132 0.421969 12.0029 0.644668 12.0029 0.876732C12.0029 1.1088 11.9132 1.3315 11.7532 1.49648L6.61402 6.74532C6.45797 6.9039 6.24802 6.99504 6.0278 6.99977C5.80759 7.0045 5.59406 6.92246 5.4316 6.77072L0.252234 1.50011C0.17246 1.41896 0.109134 1.32258 0.0658712 1.21646C0.0226087 1.11034 0.000256495 0.996573 9.05605e-05 0.881643C-7.44201e-05 0.766714 0.0219516 0.652876 0.064909 0.54663C0.107866 0.440384 0.170916 0.34381 0.250456 0.262424C0.329996 0.181037 0.424471 0.11643 0.528484 0.0722929C0.632499 0.0281554 0.744016 0.00535165 0.856668 0.00518317C1.08418 0.00484291 1.3025 0.0967222 1.46362 0.260609L6.00427 4.88995Z" fill="#909090" />
  </svg>
);

export const removeCouponIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.8" d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM9.58438 9.65938L8.55313 9.65469L7 7.80313L5.44844 9.65313L4.41563 9.65781C4.34688 9.65781 4.29063 9.60312 4.29063 9.53281C4.29063 9.50313 4.30156 9.475 4.32031 9.45156L6.35313 7.02969L4.32031 4.60938C4.30143 4.58647 4.29096 4.5578 4.29063 4.52813C4.29063 4.45938 4.34688 4.40312 4.41563 4.40312L5.44844 4.40781L7 6.25938L8.55156 4.40938L9.58281 4.40469C9.65156 4.40469 9.70781 4.45938 9.70781 4.52969C9.70781 4.55938 9.69688 4.5875 9.67813 4.61094L7.64844 7.03125L9.67969 9.45312C9.69844 9.47656 9.70938 9.50469 9.70938 9.53438C9.70938 9.60313 9.65313 9.65938 9.58438 9.65938Z" fill="#212121" fillOpacity="0.4" />
  </svg>
);

export const backButtonIcon = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="45" height="45" rx="11" fill="white" />
    <rect x="0.5" y="0.5" width="44" height="44" rx="10.5" stroke="#212121" strokeOpacity="0.1" />
    <path d="M15 22.5H34" stroke="var(--primary)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 22.5L23.3125 31" stroke="var(--primary)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 22.5L23.3125 14" stroke="var(--primary)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const backIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 12L9 16" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 12L9 8" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

export const closeIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 1.51071L13.4893 0L7.5 5.98929L1.51071 0L0 1.51071L5.98929 7.5L0 13.4893L1.51071 15L7.5 9.01071L13.4893 15L15 13.4893L9.01071 7.5L15 1.51071Z" fill="var(--primary)" />
  </svg>
);

export const couponDeleteIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.8" d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM12.3228 12.4192L10.9969 12.4132L9 10.0326L7.00513 12.4112L5.67723 12.4172C5.58884 12.4172 5.51652 12.3469 5.51652 12.2565C5.51652 12.2183 5.53058 12.1821 5.55469 12.152L8.1683 9.03817L5.55469 5.92634C5.53041 5.8969 5.51694 5.86003 5.51652 5.82188C5.51652 5.73348 5.58884 5.66116 5.67723 5.66116L7.00513 5.66719L9 8.04777L10.9949 5.6692L12.3208 5.66317C12.4092 5.66317 12.4815 5.73348 12.4815 5.82388C12.4815 5.86205 12.4674 5.89821 12.4433 5.92835L9.83371 9.04018L12.4453 12.154C12.4694 12.1842 12.4835 12.2203 12.4835 12.2585C12.4835 12.3469 12.4112 12.4192 12.3228 12.4192Z" fill="#212121" fillOpacity="0.4" />
  </svg>
);

export const backButtonIconDesktop = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="45" height="45" rx="11" fill="white" />
    <rect x="0.5" y="0.5" width="44" height="44" rx="10.5" stroke="#212121" strokeOpacity="0.1" />
    <path d="M15 22.5H34" stroke="var(--primary)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 22.5L23.3125 31" stroke="var(--primary)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 22.5L23.3125 14" stroke="var(--primary)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);


export const discountCouponIconV2 = () => (
  <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1276 8.90591L19.8282 7.60613C19.6357 7.41357 19.5394 7.12473 19.5394 6.88403V5.43983C19.5394 3.80306 18.1919 2.45514 16.5556 2.45514H15.1118C14.823 2.45514 14.5824 2.35886 14.3899 2.1663L13.0905 0.866521C11.9354 -0.28884 10.0585 -0.28884 8.90343 0.866521L7.60401 2.1663C7.41151 2.35886 7.12275 2.45514 6.88211 2.45514H5.43831C3.80201 2.45514 2.45446 3.80306 2.45446 5.43983V6.88403C2.45446 7.17287 2.35821 7.41357 2.1657 7.60613L0.86628 8.90591C-0.28876 10.0613 -0.28876 11.9387 0.86628 13.0941L2.1657 14.3939C2.35821 14.5864 2.45446 14.8753 2.45446 15.116V16.5602C2.45446 18.1969 3.80201 19.5449 5.43831 19.5449H6.88211C7.17087 19.5449 7.41151 19.6411 7.60401 19.8337L8.90343 21.1335C9.48095 21.7112 10.251 22 11.021 22C11.791 22 12.5611 21.7112 13.1386 21.1335L14.438 19.8337C14.6305 19.6411 14.9193 19.5449 15.1599 19.5449H16.6037C18.24 19.5449 19.5876 18.1969 19.5876 16.5602V15.116C19.5876 14.8271 19.6838 14.5864 19.8763 14.3939L21.1757 13.0941C22.2826 11.9869 22.2826 10.0613 21.1276 8.90591ZM19.7801 11.7462L18.4806 13.046C17.9031 13.6236 17.6144 14.3457 17.6144 15.1641V16.6083C17.6144 17.186 17.1331 17.6674 16.5556 17.6674H15.1118C14.2936 17.6674 13.5717 17.9562 12.9942 18.5339L11.6948 19.8337C11.2616 20.2188 10.636 20.2188 10.2029 19.8337L8.90343 18.5339C8.32591 17.9562 7.60401 17.6674 6.78586 17.6674H5.34206C4.76454 17.6674 4.28327 17.186 4.28327 16.6083V15.1641C4.28327 14.3457 3.99451 13.6236 3.41699 13.046L2.11757 11.7462C1.68443 11.3129 1.68443 10.6871 2.11757 10.2538L3.41699 8.95405C3.99451 8.37637 4.28327 7.65427 4.28327 6.83589V5.39168C4.28327 4.814 4.76454 4.3326 5.34206 4.3326H6.78586C7.60401 4.3326 8.32591 4.04376 8.90343 3.46608L10.2029 2.1663C10.636 1.78118 11.2616 1.78118 11.6948 2.1663L12.9942 3.46608C13.5717 4.04376 14.2936 4.3326 15.1118 4.3326H16.5556C17.1331 4.3326 17.6144 4.814 17.6144 5.39168V6.83589C17.6144 7.65427 17.9031 8.37637 18.4806 8.95405L19.7801 10.2538C20.1651 10.6871 20.1651 11.361 19.7801 11.7462Z" fill="var(--black)" />
    <path d="M13.1382 7.46169L7.36298 13.2385C6.97797 13.6236 6.97797 14.2013 7.36298 14.5864C7.55549 14.779 7.79612 14.8753 8.03675 14.8753C8.27739 14.8753 8.51802 14.779 8.71053 14.5864L14.4857 8.80961C14.8707 8.42449 14.8707 7.84681 14.4857 7.46169C14.1007 7.07657 13.5232 7.07657 13.1382 7.46169Z" fill="var(--black)" />
    <path d="M8.03675 9.09845C8.56834 9.09845 8.99929 8.66739 8.99929 8.13565C8.99929 7.60391 8.56834 7.17285 8.03675 7.17285C7.50516 7.17285 7.07422 7.60391 7.07422 8.13565C7.07422 8.66739 7.50516 9.09845 8.03675 9.09845Z" fill="var(--black)" />
    <path d="M13.8121 14.8753C14.3437 14.8753 14.7747 14.4442 14.7747 13.9125C14.7747 13.3808 14.3437 12.9497 13.8121 12.9497C13.2806 12.9497 12.8496 13.3808 12.8496 13.9125C12.8496 14.4442 13.2806 14.8753 13.8121 14.8753Z" fill="var(--black)" />
  </svg>
);

export const appliedDiscountCouponIconV2 = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1276 8.90591L19.8282 7.60613C19.6357 7.41357 19.5394 7.12473 19.5394 6.88403V5.43983C19.5394 3.80306 18.1919 2.45514 16.5556 2.45514H15.1118C14.823 2.45514 14.5824 2.35886 14.3899 2.1663L13.0905 0.866521C11.9354 -0.28884 10.0585 -0.28884 8.90343 0.866521L7.60401 2.1663C7.41151 2.35886 7.12275 2.45514 6.88211 2.45514H5.43831C3.80201 2.45514 2.45446 3.80306 2.45446 5.43983V6.88403C2.45446 7.17287 2.35821 7.41357 2.1657 7.60613L0.86628 8.90591C-0.28876 10.0613 -0.28876 11.9387 0.86628 13.0941L2.1657 14.3939C2.35821 14.5864 2.45446 14.8753 2.45446 15.116V16.5602C2.45446 18.1969 3.80201 19.5449 5.43831 19.5449H6.88211C7.17087 19.5449 7.41151 19.6411 7.60401 19.8337L8.90343 21.1335C9.48095 21.7112 10.251 22 11.021 22C11.791 22 12.5611 21.7112 13.1386 21.1335L14.438 19.8337C14.6305 19.6411 14.9193 19.5449 15.1599 19.5449H16.6037C18.24 19.5449 19.5876 18.1969 19.5876 16.5602V15.116C19.5876 14.8271 19.6838 14.5864 19.8763 14.3939L21.1757 13.0941C22.2826 11.9869 22.2826 10.0613 21.1276 8.90591ZM19.7801 11.7462L18.4806 13.046C17.9031 13.6236 17.6144 14.3457 17.6144 15.1641V16.6083C17.6144 17.186 17.1331 17.6674 16.5556 17.6674H15.1118C14.2936 17.6674 13.5717 17.9562 12.9942 18.5339L11.6948 19.8337C11.2616 20.2188 10.636 20.2188 10.2029 19.8337L8.90343 18.5339C8.32591 17.9562 7.60401 17.6674 6.78586 17.6674H5.34206C4.76454 17.6674 4.28327 17.186 4.28327 16.6083V15.1641C4.28327 14.3457 3.99451 13.6236 3.41699 13.046L2.11757 11.7462C1.68443 11.3129 1.68443 10.6871 2.11757 10.2538L3.41699 8.95405C3.99451 8.37637 4.28327 7.65427 4.28327 6.83589V5.39168C4.28327 4.814 4.76454 4.3326 5.34206 4.3326H6.78586C7.60401 4.3326 8.32591 4.04376 8.90343 3.46608L10.2029 2.1663C10.636 1.78118 11.2616 1.78118 11.6948 2.1663L12.9942 3.46608C13.5717 4.04376 14.2936 4.3326 15.1118 4.3326H16.5556C17.1331 4.3326 17.6144 4.814 17.6144 5.39168V6.83589C17.6144 7.65427 17.9031 8.37637 18.4806 8.95405L19.7801 10.2538C20.1651 10.6871 20.1651 11.361 19.7801 11.7462Z" fill="var(--green)" />
    <path d="M13.1382 7.46169L7.36298 13.2385C6.97797 13.6236 6.97797 14.2013 7.36298 14.5864C7.55549 14.779 7.79612 14.8753 8.03675 14.8753C8.27739 14.8753 8.51802 14.779 8.71053 14.5864L14.4857 8.80961C14.8707 8.42449 14.8707 7.84681 14.4857 7.46169C14.1007 7.07657 13.5232 7.07657 13.1382 7.46169Z" fill="var(--green)" />
    <path d="M8.03675 9.09845C8.56834 9.09845 8.99929 8.66739 8.99929 8.13565C8.99929 7.60391 8.56834 7.17285 8.03675 7.17285C7.50516 7.17285 7.07422 7.60391 7.07422 8.13565C7.07422 8.66739 7.50516 9.09845 8.03675 9.09845Z" fill="var(--green)" />
    <path d="M13.8121 14.8753C14.3437 14.8753 14.7747 14.4442 14.7747 13.9125C14.7747 13.3808 14.3437 12.9497 13.8121 12.9497C13.2806 12.9497 12.8496 13.3808 12.8496 13.9125C12.8496 14.4442 13.2806 14.8753 13.8121 14.8753Z" fill="var(--green)" />
  </svg>
);
export const removeCouponIconV2 = () => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.1 11.5L0 10.4L4.4 6L0 1.6L1.1 0.5L5.5 4.9L9.9 0.5L11 1.6L6.6 6L11 10.4L9.9 11.5L5.5 7.1L1.1 11.5Z" fill="#32BA46"/>
  </svg>
)