import { useMarketingPixels } from 'hooks/marketingPixels';
import { useEffect } from 'react';
import { useLanguage, useSetLanguage } from 'contexts/language';
import { get } from 'ui/lodash';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { useQueryParams } from 'hooks/location';
import { syncFont } from 'hooks/shopUtils';
import { useIsCustomDomain, useSetWebViewAlert } from 'contexts/profile';
import { injectJS } from 'ui/lib/loadJs';

function useSyncLanguage(shop) {
  const setLang = useSetLanguage();
  const lang = useLanguage();

  useEffect(() => {
    const { lang: language } = shop?.config || {};
    if (!language) {
      return;
    }
    if (lang !== language) {
      document.documentElement.setAttribute('lang', language);
      setLang(language);
    }
  }, [shop?.config?.lang]);
}

function useSyncTheme(shop) {
  const params = useQueryParams();
  useEffect(() => {
    if (!shop?._id) {
      return;
    }
    const setFilters = (value) => {
      const { rgb } = hexToCSSFilter(value, {
        forceFilterRecalculation: true,
        acceptanceLossPercentage: 0,
        maxChecks: 10
      });
      document.documentElement.style.setProperty('--primary', value);
      document.documentElement.style.setProperty('--primary20', `rgba(${rgb.join(',')},0.2)`);
      document.documentElement.style.setProperty('--primary05', `rgba(${rgb.join(',')},0.05)`);
    };

    if (params.has('themeColor')) {
      const color = `#${params.get('themeColor')}`;
      setFilters(color);
      return;
    }

    const theme = get(shop, 'config.themeColor', null);
    if (theme) {
      setFilters(theme);
    }
  }, [shop?._id]);
}

export function useThemeName(shop) {
  const params = useQueryParams();
  if (params.has('themeName')) {
    return params.get('themeName');
  }
  return shop?.config?.themeName || 'celosia';
}

export function useSyncFont(shop) {
  const font = shop?.uiConfig?.font || {};
  if (!font?.family || shop.plan === 'free') {
    font.family = 'Roboto';
    font.category = 'sans-serif';
  }
  if (!font?.category) {
    font.category = 'sans-serif';
  }
  useEffect(() => {
    syncFont(font);
  }, [shop?._id]);
}

export function useCheckWebView(shop) {
  const setWebView = useSetWebViewAlert();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.isWebView) {
        if (shop.plan !== 'premium') {
          setWebView(true);
        }
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
}

export function useRegisterServiceWorker(shop) {
  const isCustomDomain = useIsCustomDomain();
  const isSubscribed = shop.plan !== 'free';
  useEffect(() => {
    if (isSubscribed && isCustomDomain) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration()
          .then((registration) => {
            if (!registration) {
              window.workbox.register();
            }
          });
      }
    }
  }, []);
}

export function useRunAds(shop) {
  const isFree = shop.plan === 'free';
  useEffect(() => {
    if (isFree) {
      const script = document.getElementById('adsterra');
      if (script) {
        return;
      }

      window.atOptions = {
        'key': '48a6acb5c392730cf992018515929b67',
        'format': 'iframe',
        'height': 50,
        'width': 320,
        'params': {}
      };

      injectJS('adsterra', 'https://www.topcreativeformat.com/48a6acb5c392730cf992018515929b67/invoke.js');

    }
  }, [isFree]);
}

export function useSyncShop(shop, config) {
  useCheckWebView(shop);
  useSyncLanguage(shop);
  useSyncTheme(shop);
  useMarketingPixels(shop, config);
  useSyncFont(shop);
  useRegisterServiceWorker(shop);
  // useRunAds(shop);
}
