import { createContext, useContext } from 'react';
import _ from 'ui/lodash';

export const CartContext = createContext({
  cart: {
    products: []
  },
  refresh: () => {
  },
});

export function useCartContext() {
  return useContext(CartContext);
}

export function useLocalCart() {
  return useCartContext().cart;
}

export function useRefreshLocalCart() {
  return useCartContext().refresh;
}

export function useLocalCartProductCount(id) {
  const { products = [] } = useLocalCart();
  return _.sumBy(products.filter(x => x.skuId === id), 'quantity');
}
