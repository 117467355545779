import axios from './axios';

export const listOrders = async (page = 0, filters = {}) => {
  const { data } = await axios.get('/orders/list', {
    params: {
      page,
      filters,
    }
  });
  return data;
};

export const getOrder = async (id) => {
  const { data } = await axios.get('/orders/order', {
    params: {
      id,
    }
  });
  return data;
};

export const createOrder = async (payload) => {
  const { data } = await axios.post('/orders/create', payload);
  return data;
};

export const trackOrder = async (id, grpId) => {
  const { data } = await axios.get('/orders/track', {
    params: {
      id,
      grpId
    }
  });
  return data;
};

export const pollPayOrder = async (id, pgTid, tid) => {
  const { data } = await axios.post('/orders/poll', {
    id,
    pgTid,
    tid
  });
  return data;
};

export const payOrder = async (id, payload) => {
  const { data } = await axios.post('/orders/pay', {
    id,
    payload
  });
  return data;
};

export const submitRating = async (id, payload) => {
  const { data } = await axios.post('/orders/rating', payload, {
    params: {
      id,
    }
  });
  return data;
};

export const applyCode = async (id, payload) => {
  const { data } = await axios.post('/orders/apply', {
    id,
    payload
  });
  return data;
};

export const payForLink = async (id, payload) => {
  const { data } = await axios.post('/orders/payForLink', {
    id,
    data: payload
  });
  return data;
};



export const payForLinkPoll = async (id, pgTid, tid) => {
  const { data } = await axios.post('/orders/payForLinkPoll', {
    id,
    pgTid,
    tid
  });
  return data;
};

export const createOrderCancelRequest = async (id, items, note) => {
  const { data } = await axios.post('/orders/cancelRequest', {
    id,
    items, 
    note
  });
  return data.result;
};
