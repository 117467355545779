export async function syncFont(font) {
  try {
    const exists = document.querySelector(`link[id='font-${font.family}']`);
    if (exists) {
      return;
    }
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${font.family.replace(/ /g, '+')}:wght@400;500;600;700`;
    link.rel = 'stylesheet';
    link.id = `font-${font.family}`;
    await new Promise((resolve, reject) => {
      link.onload = resolve;
      link.onerror = reject;
      document.head.append(link);
    });
    document.body.style.setProperty('font-family', `${font.family}, ${font.category}`, 'important');
  } catch (e) {
    console.error(e);
  }
}
