import axios from './axios';

export const addToCart = async (id, payload) => {
  const { data } = await axios.post('/cart/add', { id, payload });
  return data;
};

export const deleteCartItem = async (id, item) => {
  const { data } = await axios.delete('/cart/delete', {
    params: {
      id, item
    }
  });
  return data;
};

export const getCart = async (id, addressId, shippingMode) => {
  const { data } = await axios.get('/cart/get', {
    params: {
      id,
      addressId,
      shippingMode
    }
  });
  return data;
};

export const updateQuantity = async (id, item, payload) => {
  const { data } = await axios.post('/cart/updateQty', { id, item, payload });
  return data;
};

export const checkout = async (id, payload) => {
  const { data } = await axios.post('/cart/checkout', { id, payload });
  return data;
};

export const checkoutOrder = async (id, payload) => {
  const { data } = await axios.post('/cart/order', { id, payload });
  return data;
};

export const applyCode = async (id, payload) => {
  const { data } = await axios.post('/cart/applyCode', { id, payload });
  return data;
};

export const validateCart = async (id) => {
  const { data } = await axios.post('/cart/validateCart', { id });
  return data;
};

export const removeCode = async (id) => {
  const { data } = await axios.delete('/cart/deleteCode', {
    params: {
      id,
    }
  });
  return data;
};
