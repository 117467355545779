export const EVENT_TYPES = {
  ADD_TO_CART: 'add-to-cart',
  PRODUCT_VIEWED:'product-viewed',
  PRODUCT_SEARCH:'product-search',
  PRODUCT_CHECKOUT:'product-checkout',
  PRODUCT_PURCHASE:'product-purchase'
};

const FB_EVENT_MAP = {
  [EVENT_TYPES.ADD_TO_CART]: 'AddToCart',
  [EVENT_TYPES.PRODUCT_VIEWED]:'ViewContent',
  [EVENT_TYPES.PRODUCT_SEARCH]:'Search',
  [EVENT_TYPES.PRODUCT_CHECKOUT]:'InitiateCheckout',
  [EVENT_TYPES.PRODUCT_PURCHASE]:'Purchase'

};

const GA_EVENT_MAP = {
  [EVENT_TYPES.ADD_TO_CART]: 'add_to_cart',
  [EVENT_TYPES.PRODUCT_VIEWED]:'view_item',
  [EVENT_TYPES.PRODUCT_SEARCH]:'search',
  [EVENT_TYPES.PRODUCT_CHECKOUT]:'begin_checkout',
  [EVENT_TYPES.PRODUCT_PURCHASE]:'purchase'

};

const handlers = [emitGaEvent, emitFBEvent]

export function emitEvent(type, data={}) {
  handlers.forEach(handler => {
    try {
      handler(type, data);
    } catch (e) {
      console.error(e);
    }
  });
}

function emitFBEvent(type, data) {
  if (window.fbq && FB_EVENT_MAP[type]) {
    window.fbq('track', FB_EVENT_MAP[type], data);
  }
}

function emitGaEvent(type, data) {
  if (window.gtag && GA_EVENT_MAP[type]) {
    window.gtag('event', GA_EVENT_MAP[type], data);
  }
}
