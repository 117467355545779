import { useEffect } from 'react';
import _ from 'ui/lodash';
import { injectJS } from 'ui/lib/loadJs';
import { emitEvent } from 'ui/lib/pixels';

async function initFBQ() {
  if (!window.fbq) {
    const script = document.createElement('script');
    script.innerHTML = `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            `;
    window.document.body.appendChild(script);
  }
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (window.fbq) {
        resolve();
        clearInterval(interval);
      }
    }, 1000);
  });
}

async function initGA(pixelId) {
  if (!window.gtag) {
    await injectJS('gtag', `https://www.googletagmanager.com/gtag/js?id=${pixelId}`);
  }
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
}

async function attachFBPixel(pixelId) {
  try {
    await initFBQ();
    if (window?.fbq?.getState) {
      const exists = !!window.fbq?.getState()
        ?.pixels
        .find(x => x.id === pixelId);
      if (exists) {
        return;
      }
    }
    window.fbq('init', pixelId);
    window.fbq('track', 'PageView');
  } catch (e) {
    console.error(e);
  }
}

async function attachGAPixel(pixelId) {
  try {
    if(window.gtag) {
      return;
    }
    await initGA(pixelId);
    const gtag = window.gtag;
    if (!gtag) {
      return;
    }
    gtag('js', new Date());
    gtag('config', pixelId);
  } catch (e) {
    console.error(e);
  }
}

export function useMarketingPixels(shop, config) {
  const attachPixels = async () => {
    if (!shop) {
      return;
    }
    const prs = [];
    const { pixels } = shop.config;
    if (pixels?.fb?.enabled && !_.isEmpty(pixels?.fb?.id)) {
      prs.push(attachFBPixel(pixels?.fb?.id));
    }
    if (pixels?.ga?.enabled && !_.isEmpty(pixels?.ga?.id)) {
      prs.push(attachGAPixel(pixels?.ga?.id, shop._id));
    }
    await Promise.all(prs);
    if(config?.events?.length) {
      config.events.forEach(event => {
        emitEvent(...event);
      });
    }
  };

  useEffect(() => {
    attachPixels();
  }, [shop?._id]);
}
